import getCookie from "./get-cookies";
var md5 = require("md5");

const Api = () => {
  const apiUrl = process.env.REACT_APP_API_ENDPOINT;
  const SALT_KEY = "p@||l!@m~^t2024";
  //   const authToken_value = localStorage.getItem("auth_token");
  const User_ID = localStorage.getItem("user_id");
  const user_email = localStorage.getItem("email_id");
  console.log({ user_email, User_ID });
  // const auth = localStorage.getItem("auth_token");
  // console.log(auth);

  async function SignIn(values) {
    try {
      //const authToken_value = await getCookie("auth_token");
      const authToken_value = localStorage.getItem("auth_token");
      const AUTHORIZATION = md5(`${SALT_KEY}` + values.password);

      values.auth_token = AUTHORIZATION;
      // localStorage.setItem('AUTHORIZATION', AUTHORIZATION);

      const obj = {
        method: "POST",
        headers: {
          Origin: "*",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(values),
      };

      const response = await fetch(`${apiUrl}/api/login`, obj);
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      return response.json();
    } catch (error) {
      console.error("Error occurred during login:", error);
      throw error;
    }
  }

  async function SignUp(values) {
    //const authToken_value = await getCookie("auth_token");
    const authToken_value = localStorage.getItem("auth_token");
    try {
      // console.log("values", values);
      const AUTHORIZATION = md5(`${SALT_KEY}` + values.password);
      values.auth_token = AUTHORIZATION;

      const obj = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(values),
      };

      const response = await fetch(`${apiUrl}/api/register`, obj);
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      return response.json();
    } catch (error) {
      console.error("Error occurred during register:", error);
      throw error;
    }
  }



  async function SocialMediaRegister(values) {
    // debugger;
    //const authToken_value = await getCookie("auth_token");
    const authToken_value = localStorage.getItem("auth_token");
    try {
      // console.log("values", values);
      const AUTHORIZATION = md5(`${SALT_KEY}` + values.provider);
      values.auth_token = AUTHORIZATION;

      const obj = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(values),
      };

      const response = await fetch(`${apiUrl}/api/social_register`, obj);
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      return response.json();
    } catch (error) {
      console.error("Error occurred during register:", error);
      throw error;
    }
  }

  // async function SignOut(values) {
  //     try {
  //         // console.log("values", values);
  //         const AUTHORIZATION = md5(`${SALT_KEY}` + authToken_value);
  //         // console.log("AUTHORIZATION", AUTHORIZATION);
  //         values.auth_token = AUTHORIZATION;

  //         const obj = {
  //             method: "POST",
  //             headers: {
  //                 "Content-Type": "application/json",
  //             },
  //             body: JSON.stringify(values),
  //         };

  //         const response = await fetch(`${apiUrl}/api/register`, obj);
  //         if (!response.ok) {
  //             throw new Error('Network response was not ok');
  //         }

  //         return response.json();
  //     } catch (error) {
  //         console.error("Error occurred during register:", error);
  //         throw error;
  //     }
  // }

  async function ForgotAPI(values) {
    //const authToken_value = await getCookie("auth_token");
    const authToken_value = localStorage.getItem("auth_token");
    try {
      const AUTHORIZATION = md5(`${SALT_KEY}` + values.email);
      values.auth_token = AUTHORIZATION;

      const obj = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(values),
      };

      const response = await fetch(`${apiUrl}/api/forget-password`, obj);
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      return response.json();
    } catch (error) {
      console.error("Error occurred during register:", error);
      throw error;
    }
  }

  async function AddUserAPI(values) {
    //const authToken_value = await getCookie("auth_token");
    const authToken_value = localStorage.getItem("auth_token");
    try {
      const AUTHORIZATION = md5(`${SALT_KEY}ADDUSER`);
      values.auth_token = AUTHORIZATION;

      const obj = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${authToken_value}`,
          "x-api-key": "add_user",
        },
        body: JSON.stringify({
          auth_token: AUTHORIZATION,
          name: values.name,
          email: values.email,
          password: values.password,
          role: values.role,
          status: values.status,
        }),
      };

      const response = await fetch(`${apiUrl}/api/add_user`, obj);
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      return response.json();
    } catch (error) {
      console.error("Error occurred during register:", error);
      throw error;
    }
  }

  async function EditUserAPI(user_id) {
    //const authToken_value = await getCookie("auth_token");
    const authToken_value = localStorage.getItem("auth_token");
    try {
      const AUTHORIZATION = md5(`${SALT_KEY}` + user_id);

      const obj = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${authToken_value}`,
          "x-api-key": "get_user_detail",
        },
        body: JSON.stringify({ auth_token: AUTHORIZATION, user_id: user_id }),
      };

      const response = await fetch(`${apiUrl}/api/get_user_detail`, obj);
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      return response.json();
    } catch (error) {
      console.error("Error occurred during register:", error);
      throw error;
    }
  }

  async function UpdateUserAPI(values, user_id) {
    //const authToken_value = await getCookie("auth_token");
    const authToken_value = localStorage.getItem("auth_token");
    try {
      const AUTHORIZATION = md5(`${SALT_KEY}` + user_id);
      values.auth_token = AUTHORIZATION;
      values.user_id = user_id;

      const obj = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${authToken_value}`,
          "x-api-key": "edit_user",
        },
        body: JSON.stringify(values),
      };

      const response = await fetch(`${apiUrl}/api/edit_user`, obj);
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      return response.json();
    } catch (error) {
      console.error("Error occurred during register:", error);
      throw error;
    }
  }

  async function LiveVideoAPI() {
    //const authToken_value = await getCookie("auth_token");
    const authToken_value = localStorage.getItem("auth_token");
    try {
      const AUTHORIZATION = md5(`${SALT_KEY}LIVE_VIDEOS`);

      const obj = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${authToken_value}`,
          "x-api-key": "live-videos",
        },
        body: JSON.stringify({
          auth_token: AUTHORIZATION,
        }),
      };

      const response = await fetch(`${apiUrl}/api/live-videos`, obj);
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      return response.json();
    } catch (error) {
      console.error("Error occurred during register:", error);
      throw error;
    }
  }

  async function UpcomingVideoAPI() {
    //const authToken_value = await getCookie("auth_token");
    const authToken_value = localStorage.getItem("auth_token");
    try {
      const AUTHORIZATION = md5(`${SALT_KEY}UPCOMINGMEETINGSLIST`);

      const obj = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${authToken_value}`,
          "x-api-key": "upcoming_session_video",
        },
        body: JSON.stringify({
          auth_token: AUTHORIZATION,
          user_id: User_ID,
        }),
      };

      const response = await fetch(`${apiUrl}/api/upcoming_session_video`, obj);
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      return response.json();
    } catch (error) {
      console.error("Error occurred during register:", error);
      throw error;
    }
  }

  async function PreviousMeeting(values) {
    //const authToken_value = await getCookie("auth_token");
    const authToken_value = localStorage.getItem("auth_token");
    try {
      const AUTHORIZATION = md5(`${SALT_KEY}PREVIOUSMEETINGSLIST`);

      const obj = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${authToken_value}`,
          "x-api-key": "previous_session_video",
        },
        body: JSON.stringify({
          auth_token: AUTHORIZATION,
          status: values.status,
          from_date: values.fromDate,
          to_date: values.toDate,
          from_time: values.fromTime,
          to_time: values.toTime,
          page: values.page,
          user_id: User_ID,
        }),
      };

      const response = await fetch(`${apiUrl}/api/previous_session_video`, obj);
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      return response.json();
    } catch (error) {
      console.error("Error occurred during register:", error);
      throw error;
    }
  }

  async function UsersList(values) {
    //const authToken_value = await getCookie("auth_token");
    const authToken_value = localStorage.getItem("auth_token");
    try {
      const AUTHORIZATION = md5(`${SALT_KEY}USERSLIST`);
      // values.auth_token = AUTHORIZATION;

      // const authToken_value = "your_bearer_token_here"; // Define your bearer token
      // const apiUrl = "https://dev4.technotackle.in"; // Define your API base URL

      const obj = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${authToken_value}`,
          "x-api-key": "users_list",
        },
        body: JSON.stringify({
          auth_token: AUTHORIZATION,
          page: values.page,
          name: values.name,
          email: values.email,
        }),
      };

      const response = await fetch(`${apiUrl}/api/users_list`, obj);
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      return response.json();
    } catch (error) {
      console.error("Error occurred during register:", error);
      throw error;
    }
  }

  async function MeetingList(values) {
    console.log(values);
    //const authToken_value = await getCookie("auth_token");
    const authToken_value = localStorage.getItem("auth_token");

    // console.log("MeetingList--", values);
    try {
      const AUTHORIZATION = md5(`${SALT_KEY}MEETINGSLIST`);

      const obj = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${authToken_value}`,
          "x-api-key": "meetings_list",
        },
        body: JSON.stringify({
          auth_token: AUTHORIZATION,
          status: values.status,
          from_date: values.fromDate,
          to_date: values.toDate,
          from_time: values.fromTime,
          to_time: values.toTime,
          page: values.page,
          search_keyword: values.meet,
          // status: "UPCOMING",
          // from_date: "01-01-2024",
          // to_date: "30-03-2024",
          // from_time: "09:30:00",
          // to_time: "12:00:00",
          user_id: User_ID,
        }),
      };

      const response = await fetch(`${apiUrl}/api/meetings_list`, obj);
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      return response.json();
    } catch (error) {
      console.error("Error occurred during register:", error);
      throw error;
    }
  }

  async function MeetingDetails(values) {
    //const authToken_value = await getCookie("auth_token");
    const authToken_value = localStorage.getItem("auth_token");
    try {
      const AUTHORIZATION = md5(`${SALT_KEY}` + values);
      // console.log("MeetingDetails", AUTHORIZATION);

      const obj = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${authToken_value}`,
          "x-api-key": "single_meeting_detail",
        },
        body: JSON.stringify({
          auth_token: AUTHORIZATION,
          meeting_id: values,
          user_id: User_ID,
        }),
      };

      const response = await fetch(`${apiUrl}/api/single_meeting_detail`, obj);
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      return response.json();
    } catch (error) {
      console.error("Error occurred during register:", error);
      throw error;
    }
  }
  // async function SingleMeeting() {
  //     try {
  //         const AUTHORIZATION = md5(`${SALT_KEY}meeting_id`);
  //         // console.log("AUTHORIZATION", AUTHORIZATION);

  //         const obj = {
  //             method: "POST",
  //             headers: {
  //                 "Content-Type": "application/json",
  //                 'Authorization': `Bearer ${authToken_value}`,
  //                 "x-api-key": "single_meeting_detail",
  //             },
  //             body: JSON.stringify({
  //                 auth_token: AUTHORIZATION,
  //             }),
  //         };

  //         const response = await fetch(`${apiUrl}/api/single_meeting_detail`, obj);
  //         if (!response.ok) {
  //             throw new Error('Network response was not ok');
  //         }

  //         return response.json();
  //     } catch (error) {
  //         console.error("Error occurred during register:", error);
  //         throw error;
  //     }
  // }

  async function FavoriteList(values) {
    //const authToken_value = await getCookie("auth_token");
    const authToken_value = localStorage.getItem("auth_token");
    try {
      const AUTHORIZATION = md5(`${SALT_KEY}FAVORITEMEETINGSLIST`);

      const obj = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${authToken_value}`,
          "x-api-key": "favorite_video",
        },
        body: JSON.stringify({
          auth_token: AUTHORIZATION,
          user_id: User_ID,
          page: values.page,
        }),
      };

      const response = await fetch(`${apiUrl}/api/favorite_video`, obj);
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      return response.json();
    } catch (error) {
      console.error("Error occurred during register:", error);
      throw error;
    }
  }

  async function FavoriteMeetings(values) {
    //const authToken_value = await getCookie("auth_token");
    const authToken_value = localStorage.getItem("auth_token");
    try {
      const AUTHORIZATION = md5(`${SALT_KEY}FAVORITEMEETINGSLIST`);

      const obj = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${authToken_value}`,
          "x-api-key": "favorite_video",
        },
        body: JSON.stringify({
          auth_token: AUTHORIZATION,
          user_id: User_ID,
          page: values.page,
        }),
      };

      const response = await fetch(`${apiUrl}/api/favorite_video`, obj);
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      return response.json();
    } catch (error) {
      console.error("Error occurred during register:", error);
      throw error;
    }
  }

  async function AddFavoriteVideo(values) {
    //const authToken_value = await getCookie("auth_token");
    const authToken_value = localStorage.getItem("auth_token");
    try {
      const AUTHORIZATION = md5(`${SALT_KEY}` + User_ID);
      // console.log("AddFavoriteVideo", values);

      const obj = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${authToken_value}`,
          "x-api-key": "add_to_favorite",
        },
        body: JSON.stringify({
          auth_token: AUTHORIZATION,
          video_id: values,
          user_id: User_ID,
        }),
      };

      const response = await fetch(`${apiUrl}/api/add_to_favorite`, obj);
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      return response.json();
    } catch (error) {
      console.error("Error occurred during register:", error);
      throw error;
    }
  }

  async function RemoveFavoriteVideo(values) {
    //const authToken_value = await getCookie("auth_token");
    const authToken_value = localStorage.getItem("auth_token");
    try {
      const AUTHORIZATION = md5(`${SALT_KEY}` + User_ID);
      // console.log("remove_from_favorite", values);

      const obj = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${authToken_value}`,
          "x-api-key": "remove_from_favorite",
        },
        body: JSON.stringify({
          auth_token: AUTHORIZATION,
          video_id: values,
          user_id: User_ID,
        }),
      };

      const response = await fetch(`${apiUrl}/api/remove_from_favorite`, obj);
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      return response.json();
    } catch (error) {
      console.error("Error occurred during register:", error);
      throw error;
    }
  }

  async function AddLikeVideo(values) {
    //const authToken_value = await getCookie("auth_token");
    const authToken_value = localStorage.getItem("auth_token");
    try {
      const AUTHORIZATION = md5(`${SALT_KEY}` + User_ID);
      // console.log("AddFavoriteVideo", values);

      const obj = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${authToken_value}`,
          "x-api-key": "add_to_liked",
        },
        body: JSON.stringify({
          auth_token: AUTHORIZATION,
          video_id: values,
          user_id: User_ID,
        }),
      };

      const response = await fetch(`${apiUrl}/api/add_to_liked`, obj);
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      return response.json();
    } catch (error) {
      console.error("Error occurred during register:", error);
      throw error;
    }
  }

  async function RemoveLikeVideo(values) {
    //const authToken_value = await getCookie("auth_token");
    const authToken_value = localStorage.getItem("auth_token");
    try {
      const AUTHORIZATION = md5(`${SALT_KEY}` + User_ID);
      // console.log("AddFavoriteVideo", values);

      const obj = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${authToken_value}`,
          "x-api-key": "remove_from_liked",
        },
        body: JSON.stringify({
          auth_token: AUTHORIZATION,
          video_id: values,
          user_id: User_ID,
        }),
      };

      const response = await fetch(`${apiUrl}/api/remove_from_liked`, obj);
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      return response.json();
    } catch (error) {
      console.error("Error occurred during register:", error);
      throw error;
    }
  }

  async function LikedList(values) {
    console.log(values);
    //const authToken_value = await getCookie("auth_token");
    const authToken_value = localStorage.getItem("auth_token");
    try {
      const AUTHORIZATION = md5(`${SALT_KEY}LIKEDMEETINGSLIST`);

      const obj = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${authToken_value}`,
          "x-api-key": "liked_video",
        },
        body: JSON.stringify({
          auth_token: AUTHORIZATION,
          user_id: User_ID,
          page: values.page,
        }),
      };

      const response = await fetch(`${apiUrl}/api/liked_video`, obj);
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      return response.json();
    } catch (error) {
      console.error("Error occurred during register:", error);
      throw error;
    }
  }

  async function LikedMeetings(values) {
    //const authToken_value = await getCookie("auth_token");
    const authToken_value = localStorage.getItem("auth_token");
    try {
      const AUTHORIZATION = md5(`${SALT_KEY}LIKEDMEETINGSLIST`);

      const obj = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${authToken_value}`,
          "x-api-key": "liked_video",
        },
        body: JSON.stringify({
          auth_token: AUTHORIZATION,
          user_id: User_ID,
          page: values.page,
        }),
      };

      const response = await fetch(`${apiUrl}/api/liked_video`, obj);
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      return response.json();
    } catch (error) {
      console.error("Error occurred during register:", error);
      throw error;
    }
  }

  // async function TitleMeetingCount(values) {
  //   //const authToken_value = await getCookie("auth_token");
  const authToken_value = localStorage.getItem("auth_token");
  //   try {
  //     const AUTHORIZATION = md5(`${SALT_KEY}MEETING_COUNT` + User_ID);
  //     // console.log("AddFavoriteVideo", values);

  //     const obj = {
  //       method: "POST",
  //       headers: {
  //         "Content-Type": "application/json",
  //         Authorization: `Bearer ${authToken_value}`,
  //         "x-api-key": "title-wise-meeting-count",
  //       },
  //       body: JSON.stringify({
  //         auth_token: AUTHORIZATION,
  //         page: values.page,
  //         search: values.title,
  //       }),
  //     };

  //     const response = await fetch(
  //       `${apiUrl}/api/title-wise-meeting-count`,
  //       obj
  //     );
  //     if (!response.ok) {
  //       throw new Error("Network response was not ok");
  //     }

  //     return response.json();
  //   } catch (error) {
  //     console.error("Error occurred during register:", error);
  //     throw error;
  //   }
  // }

  async function TitleMeetingCount(values) {
    //const authToken_value = await getCookie("auth_token");
    const authToken_value = localStorage.getItem("auth_token");
    try {
      const AUTHORIZATION = md5(`${SALT_KEY}CLICK_COUNT` + User_ID);
      // console.log("AddFavoriteVideo", values);

      const obj = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${authToken_value}`,
          "x-api-key": "title-wise-click-count",
        },
        body: JSON.stringify({
          auth_token: AUTHORIZATION,
          page: values.page,
          search: values.title,
          user_id: User_ID,
        }),
      };

      const response = await fetch(`${apiUrl}/api/title-wise-click-count`, obj);
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      return response.json();
    } catch (error) {
      console.error("Error occurred during register:", error);
      throw error;
    }
  }
  async function getadmin_meeting_List(values) {
    //const authToken_value = await getCookie("auth_token");
    const authToken_value = localStorage.getItem("auth_token");
    try {
      const AUTHORIZATION = md5(`${SALT_KEY}MEETINGSLIST`);
      // console.log("AddFavoriteVideo", values);

      const obj = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${authToken_value}`,
          "x-api-key": "title-wise-click-count",
        },
        body: JSON.stringify({
          auth_token: AUTHORIZATION,
          status: values.status,
          from_date: values.fromDate,
          to_date: values.toDate,
          from_time: values.fromTime,
          to_time: values.toTime,
          page: values.page,
          search_keyword: values.meet,
          user_id: User_ID,
        }),
      };
      const response = await fetch(`${apiUrl}/api/admin_meetings_list`, obj);
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      return response.json();
    } catch (error) {
      console.error("Error occurred during register:", error);
      throw error;
    }
  }

  async function Change_meeting_status(values) {
    console.log(values);
    //const authToken_value = await getCookie("auth_token");
    const authToken_value = localStorage.getItem("auth_token");
    try {
      const AUTHORIZATION = md5(`${SALT_KEY + values.video_id}`);
      // console.log("AddFavoriteVideo", values);

      const obj = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${authToken_value}`,
          "x-api-key": "title-wise-click-count",
        },
        body: JSON.stringify({
          auth_token: AUTHORIZATION,
          video_id: values.video_id,
          is_active: values.is_active, //0 for status in active - 1 for status active
        }),
      };
      const response = await fetch(`${apiUrl}/api/change_meeting_status`, obj);
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      return response.json();
    } catch (error) {
      console.error("Error occurred during register:", error);
      throw error;
    }
  }

  // async function TrendingTopics(values) {
  //   //const authToken_value = await getCookie("auth_token");
  // const authToken_value = localStorage.getItem("auth_token");
  //   try {
  //     const AUTHORIZATION = md5(`${SALT_KEY}TRENDING_TOPICS` + User_ID);
  //     // console.log("AddFavoriteVideo", values);

  //     const obj = {
  //       method: "POST",
  //       headers: {
  //         "Content-Type": "application/json",
  //         Authorization: `Bearer ${authToken_value}`,
  //         "x-api-key": "trending-topics",
  //       },
  //       body: JSON.stringify({
  //         auth_token: AUTHORIZATION,
  //       }),
  //     };

  //     const response = await fetch(`${apiUrl}/api/trending-topics`, obj);
  //     if (!response.ok) {
  //       throw new Error("Network response was not ok");
  //     }

  //     return response.json();
  //   } catch (error) {
  //     console.error("Error occurred during register:", error);
  //     throw error;
  //   }
  // }
  async function click_count_percentage(values) {
    //const authToken_value = await getCookie("auth_token");
    const authToken_value = localStorage.getItem("auth_token");
    try {
      const AUTHORIZATION = md5(`${SALT_KEY}CLICK_PERCENTAGE` + User_ID);
      // console.log("AddFavoriteVideo", values);

      const obj = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${authToken_value}`,
          "x-api-key": "trending-topics",
        },
        body: JSON.stringify({
          auth_token: AUTHORIZATION,
          user_id: User_ID,
        }),
      };
      const response = await fetch(`${apiUrl}/api/click_count_percentage`, obj);
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      return response.json();
    } catch (error) {
      console.error("Error occurred during register:", error);
      throw error;
    }
  }

  async function SessionsCount(values) {
    //const authToken_value = await getCookie("auth_token");
    const authToken_value = localStorage.getItem("auth_token");
    try {
      const AUTHORIZATION = md5(
        `${SALT_KEY}SESSION_COUNT_AND_MEETINGS` + User_ID
      );
      // console.log("AddFavoriteVideo", values);

      const obj = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${authToken_value}`,
          "x-api-key": "sessions-count-and-meetings",
        },
        body: JSON.stringify({
          auth_token: AUTHORIZATION,
          time_range: values.selectedFilter,
          from_date: values.from_date,
          to_date: values.to_date,
        }),
      };

      const response = await fetch(
        `${apiUrl}/api/sessions-count-and-meetings`,
        obj
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      return response.json();
    } catch (error) {
      console.error("Error occurred during register:", error);
      throw error;
    }
  }

  async function User_report(values) {
    //const authToken_value = await getCookie("auth_token");
    const authToken_value = localStorage.getItem("auth_token");
    try {
      const AUTHORIZATION = md5(`${SALT_KEY}USER_REPORT_CHART`);
      // console.log("AddFavoriteVideo", values);

      const obj = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${authToken_value}`,
          "x-api-key": "sessions-count-and-meetings",
        },
        body: JSON.stringify({
          auth_token: AUTHORIZATION,
          time_range: values,
        }),
      };

      const response = await fetch(`${apiUrl}/api/user_report_pie_chat`, obj);
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      return response.json();
    } catch (error) {
      console.error("Error occurred during register:", error);
      throw error;
    }
  }

  async function EditProfile(body) {
    console.log(body);
    //const authToken_value = await getCookie("auth_token");
    const authToken_value = localStorage.getItem("auth_token");
    try {
      const AUTHORIZATION = md5(`${SALT_KEY}` + User_ID);
      // console.log("AddFavoriteVideo", values);

      const obj = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${authToken_value}`,
          "x-api-key": "edit_profile",
        },
        body: JSON.stringify({
          auth_token: AUTHORIZATION,
          user_id: User_ID,
          first_name: body.first_name,
          last_name: body.last_name,
          profile_image: body.profile_image,
          dob: body.dob,
          present_address: body.present_address,
          parmanent_address: body.parmanent_address,
          city: body.city,
          country: body.country,
          postal_code: body.postal_code,
        }),
      };

      const response = await fetch(`${apiUrl}/api/edit_profile`, obj);
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      return response.json();
    } catch (error) {
      console.error("Error occurred during register:", error);
      throw error;
    }
  }

  async function ChangePassword(values) {
    ////const authToken_value = await getCookie("auth_token");
    const authToken_value = localStorage.getItem("auth_token");
    try {
      const AUTHORIZATION = md5(`${SALT_KEY}` + User_ID + "CHANGE_PASSWORD");
      // console.log("CHANGE_PASSWORD", AUTHORIZATION);

      const obj = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${authToken_value}`,
          "x-api-key": "change-password",
        },
        body: JSON.stringify({
          auth_token: AUTHORIZATION,
          current_password: values.currentpassword,
          password: values.newpassword,
          user_id: User_ID,
        }),
      };

      const response = await fetch(`${apiUrl}/api/change-password`, obj);
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      return response.json();
    } catch (error) {
      console.error("Error occurred during register:", error);
      throw error;
    }
  }

  async function GlobalSearchList(value) {
    ////const authToken_value = await getCookie("auth_token");
    const authToken_value = localStorage.getItem("auth_token");
    try {
      const AUTHORIZATION = md5(`${SALT_KEY}MEETINGSLIST`);

      const obj = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${authToken_value}`,
          "x-api-key": "meetings_list",
        },
        body: JSON.stringify({
          auth_token: AUTHORIZATION,
          user_id: User_ID,
          search_keyword: value,
        }),
      };

      const response = await fetch(`${apiUrl}/api/meetings_list`, obj);
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      return response.json();
    } catch (error) {
      console.error("Error occurred during register:", error);
      throw error;
    }
  }

  async function GetUserProfile() {
    //const authToken_value = await getCookie("auth_token");
    const authToken_value = localStorage.getItem("auth_token");
    console.log(authToken_value);
    try {
      const AUTHORIZATION = md5(`${SALT_KEY}` + User_ID);
      const obj = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${authToken_value}`,
          "x-api-key": "get_user_profile",
        },
        body: JSON.stringify({
          auth_token: AUTHORIZATION,
          user_id: User_ID,
        }),
      };

      const response = await fetch(`${apiUrl}/api/get_user_profile`, obj);
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      return response.json();
    } catch (error) {
      console.error("Error occurred during register:", error);
      throw error;
    }
  }

  async function VideoView(values) {
    //const authToken_value = await getCookie("auth_token");
    const authToken_value = localStorage.getItem("auth_token");
    try {
      const AUTHORIZATION = md5(`${SALT_KEY}VIDEO_VIEW` + values);
      const obj = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${authToken_value}`,
          "x-api-key": "video-view",
        },
        body: JSON.stringify({
          auth_token: AUTHORIZATION,
          user_id: User_ID,
          video_id: values,
        }),
      };

      const response = await fetch(`${apiUrl}/api/video-view`, obj);
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      return response.json();
    } catch (error) {
      console.error("Error occurred during register:", error);
      throw error;
    }
  }

  async function Resetpassword(values) {
    console.log(values);
    // //const authToken_value = await getCookie("auth_token");
    const authToken_value = localStorage.getItem("auth_token");
    try {
      const AUTHORIZATION = md5(`${SALT_KEY}` + values.password + values.email);
      const obj = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${values.token}`,
          "x-api-key": "email",
        },
        body: JSON.stringify({
          auth_token: AUTHORIZATION,
          email: values.email,
          password: values.password,
          token: values.token,
        }),
      };

      const response = await fetch(`${apiUrl}/api/reset-password`, obj);
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      return response.json();
    } catch (error) {
      console.error("Error occurred during register:", error);
      throw error;
    }
  }

  async function ReportTitle() {
    //const authToken_value = await getCookie("auth_token");
    const authToken_value = localStorage.getItem("auth_token");
    try {
      const AUTHORIZATION = md5(`${SALT_KEY}REPORTTYPE`);
      const obj = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${authToken_value}`,
          "x-api-key": "report_types",
        },
        body: JSON.stringify({
          auth_token: AUTHORIZATION,
        }),
      };

      const response = await fetch(`${apiUrl}/api/report_types`, obj);
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      return response.json();
    } catch (error) {
      console.error("Error occurred during register:", error);
      throw error;
    }
  }

  async function AddReport(value) {
    //const authToken_value = await getCookie("auth_token");
    const authToken_value = localStorage.getItem("auth_token");
    try {
      const AUTHORIZATION = md5(`${SALT_KEY}ADDREPORT`);
      const obj = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${authToken_value}`,
          "x-api-key": "add_report",
        },
        body: JSON.stringify({
          auth_token: AUTHORIZATION,
          report_id: value.id,
          user_id: User_ID,
          video_id: value.videoId,
          comments: value.comments,
        }),
      };

      const response = await fetch(`${apiUrl}/api/add_report`, obj);
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      return response.json();
    } catch (error) {
      console.error("Error occurred during register:", error);
      throw error;
    }
  }

  async function ReportVideoList(values) {
    console.log(values);
    //const authToken_value = await getCookie("auth_token");
    const authToken_value = localStorage.getItem("auth_token");
    try {
      const AUTHORIZATION = md5(`${SALT_KEY}REPORTLIST`);
      const obj = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${authToken_value}`,
          "x-api-key": "report_list",
        },
        body: JSON.stringify({
          auth_token: AUTHORIZATION,
          page: values.page,
          video_title: values.meeting,
          from_date: values.selectfromdate,
          to_date: values.selecttodate,
        }),
      };

      const response = await fetch(`${apiUrl}/api/report_list`, obj);
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      return response.json();
    } catch (error) {
      console.error("Error occurred during register:", error);
      throw error;
    }
  }

  async function ReportList() {
    //const authToken_value = await getCookie("auth_token");
    const authToken_value = localStorage.getItem("auth_token");
    try {
      const AUTHORIZATION = md5(`${SALT_KEY}REPORTLIST`);
      const obj = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${authToken_value}`,
          "x-api-key": "report_list",
        },
        body: JSON.stringify({
          auth_token: AUTHORIZATION,
        }),
      };

      const response = await fetch(`${apiUrl}/api/report_list`, obj);
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      return response.json();
    } catch (error) {
      console.error("Error occurred during register:", error);
      throw error;
    }
  }

  async function DashboardReportList(value) {
    //const authToken_value = await getCookie("auth_token");
    const authToken_value = localStorage.getItem("auth_token");
    try {
      const AUTHORIZATION = md5(`${SALT_KEY}REPORTLIST`);
      const obj = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${authToken_value}`,
          "x-api-key": "dashboard_report_list",
        },
        body: JSON.stringify({
          auth_token: AUTHORIZATION,
          page: value.page,
        }),
      };

      const response = await fetch(`${apiUrl}/api/dashboard_report_list`, obj);
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      return response.json();
    } catch (error) {
      console.error("Error occurred during register:", error);
      throw error;
    }
  }

  async function VerifyOtp(value) {
    //const authToken_value = await getCookie("auth_token");
    const authToken_value = localStorage.getItem("auth_token");
    try {
      const AUTHORIZATION = md5(`${SALT_KEY}` + value.otp);
      const obj = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${authToken_value}`,
          "x-api-key": "verify_otp",
        },
        body: JSON.stringify({
          auth_token: AUTHORIZATION,
          email: user_email,
          login_otp: value.otp,
        }),
      };

      const response = await fetch(`${apiUrl}/api/verify_otp`, obj);
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      return response.json();
    } catch (error) {
      console.error("Error occurred during register:", error);
      throw error;
    }
  }
  async function Resend_otp({ email }) {
    console.log(email);
    //const authToken_value = await getCookie("auth_token");
    const authToken_value = localStorage.getItem("auth_token");
    try {
      const AUTHORIZATION = md5(`${SALT_KEY}` + email);
      const obj = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${authToken_value}`,
          "x-api-key": "dashboard_report_list",
        },
        body: JSON.stringify({
          auth_token: AUTHORIZATION,
          email: email,
        }),
      };

      const response = await fetch(`${apiUrl}/api/resend_otp`, obj);
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      return response.json();
    } catch (error) {
      console.error("Error occurred during register:", error);
      throw error;
    }
  }

  async function count_channel_click(values) {
    // console.log(velues);
    //const authToken_value = await getCookie("auth_token");
    const authToken_value = localStorage.getItem("auth_token");
    try {
      const AUTHORIZATION = md5(
        `${SALT_KEY}` + values.user_id + values.channel + values.meeting_id
      );
      console.log(AUTHORIZATION);
      const obj = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${authToken_value}`,
          "x-api-key": "count_channel_click",
        },
        body: JSON.stringify({
          auth_token: AUTHORIZATION,
          user_id: values.user_id,
          channel: values.channel,
          meeting_id: values.meeting_id,
        }),
      };
      const response = await fetch(`${apiUrl}/api/count_channel_click`, obj);
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      return response.json();
    } catch (error) {
      console.error("Error occurred during register:", error);
      throw error;
    }
  }

  async function rssFeeds_json(values) {
    // console.log(velues);
    //const authToken_value = await getCookie("auth_token");
    const authToken_value = localStorage.getItem("auth_token");
    try {
      // const AUTHORIZATION = md5(
      //   `${SALT_KEY}` + values.user_id + values.channel + values.meeting_id
      // );
      // console.log(AUTHORIZATION);
      const obj = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          // Authorization: `Bearer ${authToken_value}`,
          "x-api-key": "count_channel_click",
        },
      };
      const response = await fetch(
        `https://www.parliament.gov.za/na-press-releases-json`,
        obj
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      return response.json();
    } catch (error) {
      console.error("Error occurred during register:", error);
      throw error;
    }
  }
  // const rssFeeds_json = async () => {
  //   try {
  //     const obj = {
  //       method: "GET",
  //       headers: {
  //         // "Content-Type": "application/json",
  //         // Authorization: `Bearer ${authToken_value}`,
  //         // "x-api-key": "count_channel_click",
  //       },
  //     };
  //     const response = await fetch(
  //       `https://www.parliament.gov.za/na-press-releases-json`,
  //       obj
  //     );
  //     if (!response.ok) {
  //       throw new Error("Network response was not ok");
  //     }

  //     return response.json();
  //   } catch (error) {
  //     console.error("Error occurred during register:", error);
  //     throw error;
  //   }
  // };

  return {
    SignIn,
    SignUp,
    // SignOut,
    ForgotAPI,
    AddUserAPI,
    EditUserAPI,
    UpdateUserAPI,
    LiveVideoAPI,
    UsersList,
    PreviousMeeting,
    MeetingList,
    MeetingDetails,
    // SingleMeeting,
    FavoriteList,
    FavoriteMeetings,
    UpcomingVideoAPI,
    AddFavoriteVideo,
    RemoveFavoriteVideo,
    AddLikeVideo,
    RemoveLikeVideo,
    LikedList,
    LikedMeetings,
    TitleMeetingCount,
    // TrendingTopics,
    click_count_percentage,
    SessionsCount,
    EditProfile,
    ChangePassword,
    GlobalSearchList,
    GetUserProfile,
    VideoView,
    ReportTitle,
    AddReport,
    ReportVideoList,
    ReportList,
    DashboardReportList,
    VerifyOtp,
    Resetpassword,
    Resend_otp,
    count_channel_click,
    User_report,
    rssFeeds_json,
    getadmin_meeting_List,
    Change_meeting_status,
    SocialMediaRegister
  };

  // return (
  //     <div>

  //         <p>{SALT}</p>
  //         <p>{apiUrl}</p>
  //     </div>

  // )
};

export default Api;
