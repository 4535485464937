import React, { useEffect} from "react";
import "./LiveVideos.css";
import { IoClose } from "react-icons/io5";

const Modal = ({ embedLink, socialType, onClose }) => {

    useEffect(() => {
        // Check if the SDK has already been loaded
        if (!window.FB) {
          // Load the Facebook SDK script dynamically
          (function (d, s, id) {
            var js,
              fjs = d.getElementsByTagName(s)[0];
            if (d.getElementById(id)) return;
            js = d.createElement(s);
            js.id = id;
            js.src = 'https://connect.facebook.net/en_US/sdk.js';
            fjs.parentNode.insertBefore(js, fjs);
          })(document, 'script', 'facebook-jssdk');
        }

        // Initialize the Facebook SDK when it's ready
        window.fbAsyncInit = function () {
          window.FB.init({
            xfbml: true,
            version: 'v12.0',
          });
          // Render any Facebook social plugins once the SDK is ready
          window.FB.XFBML.parse();
        };
      }, []);

      useEffect(() => {
        if (socialType === "facebook" && embedLink) {
          // Call the Facebook SDK's parse function to re-render the Facebook post
          if (window.FB) {
            window.FB.XFBML.parse(); // Re-parse the dynamic content
          }
        }
      }, [embedLink, socialType]); 

    return (
        <>

            {socialType == "youtube" && (
                <div className="modal" style={{ display: 'block' }}>
                    <div className="modal-dialog modal-dialog-centered modal-lg">
                        <div className="modal-content p-4 position-relative">
                            <button onClick={onClose} className="btn close-button modal_close"><IoClose /></button>
                            {embedLink && (
                                <iframe
                                    width="560"
                                    height="315"
                                    src={embedLink}
                                    title="YouTube video"
                                    frameBorder="0"
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                    allowFullScreen
                                    className='w-100'
                                />
                            )}
                        </div>
                    </div>

                </div>
            )}


            {socialType == "facebook" && (

                <div className="modal" style={{ display: 'block' }}>
                    <div className="modal-dialog modal-dialog-centered modal-lg fb_fit-width">
                        <div className="modal-content p-4 position-relative">
                            <button onClick={onClose} className="btn close-button modal_close"><IoClose /></button>

                            <div
                                className="fb-post"
                                data-href={`https://www.facebook.com/ParliamentofRSA/videos/${embedLink}`}
                                data-width="500"
                            ></div>
                        </div>
                    </div>

                </div>
            )}



        </>
    );
};

export default Modal;
