import React, { useState, useEffect, useRef } from "react";
import RSS from "./rss.xml";
import { Link } from "react-router-dom";
import local_rssdata from '../../assets/json/rssfeeds.json'
import TabsComponent from "../customTabs/CustomTabs";
function RSSFeed({ barChartHeight }) {
  const [feedItems, setFeedItems] = useState([]);

  useEffect(() => {
    // URL of the RSS feed
    // const rssUrl = RSS;

    // // Fetch the RSS feed
    // fetch(rssUrl)
    //   .then((response) => response.text())
    //   .then((xmlString) => {
    //     // Convert XML string to DOM object
    //     const parser = new DOMParser();
    //     const xml = parser.parseFromString(xmlString, "text/xml");

    //     // Parse the XML and extract feed items
    //     const items = Array.from(xml.querySelectorAll("item")).map((item) => ({
    //       title: item.querySelector("title").textContent,
    //       description: item.querySelector("description").textContent,
    //       link: item.querySelector("link").textContent,
    //     }));

    //     // Update state with the feed items
    //     setFeedItems(items);
    //   })
    //   .catch((error) => {
    //     console.error("Failed to fetch RSS feed:", error);
    //   });
    setFeedItems(local_rssdata)
  }, []);

  // const handleRssfeed = () => {
  //   const newUrl = `${window.location.origin}/admin/press-release`;
  //   window.open(newUrl, "_blank");
  // };
  return (
    <div
      className="report_card position-relative"
      style={{ height: `calc(${barChartHeight}px)` }}
    >
      <TabsComponent data={feedItems}/>
      {/* <div className=""> */}
        {/* <ul class="nav nav-tabs rss_tab mt-0 border-0">
          <li class="nav-item">
            <a class="nav-link active" data-bs-toggle="tab" href="#home">
              Press Releases
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link" data-bs-toggle="tab" href="#LATESTNEWS">
              Latest News
            </a>
          </li>
        </ul> */}
        {/* <div class="tab-content p-3">
          <div class="tab-pane active" id="home"> */}
            {/* {feedItems.map((item, index) => (
              <div key={index} className="rss_data">
                <Link
                  to={item.link}
                  target="_blank"
                  className="d-block"
                  //   target="_blank"
                >
                  <h3>{item.title}</h3>
                  <p className="m-0">{item.description || ""}</p>
                </Link>
              </div>
            ))} */}
            {/* {local_rssdata.tabs[0]?.title.items&& local_rssdata.tabs[0]?.title.items.map((news,index)=>(<>
              <Link to={news.href} target="_blank" className="d-block">
                  <h3>{news.title}</h3>
                  
                </Link>
            </>)) } */}
          {/* </div> */}
          {/* <div class="tab-pane fade" id="LATESTNEWS"> */}
            {/* {feedItems.map((item, index) => (
              <div key={index} className="rss_data">
                <Link to={item.link} target="_blank" className="d-block">
                  <h3>{item.title}</h3>
                  <p className="m-0">{item.description || ""}</p>
                </Link>
              </div>
            ))} */}
          {/* </div>
        </div>
      </div> */}
      <button className="show_btn w-full">
        Show More
      </button>
    </div>
  );
}

export default RSSFeed;
