import React, { useState, useEffect } from "react";
import local_rssdata from '../../assets/json/rssfeeds.json'
import "./presspage.css";
import TabsComponent from "../customTabs/CustomTabs";

const PressPage = () => {
    const [feedItems, setFeedItems] = useState([]);
    useEffect(() => {
      
        setFeedItems(local_rssdata)
      }, []);
  return (
    <>
    <div className="page_container press-realese-container">

    <TabsComponent data={feedItems}/>
    </div>
    </>
  );
};

export default PressPage;
