import React, { useState, useEffect } from "react";
import "./register.css";
import "../login/login.css";
import { Link, useNavigate } from "react-router-dom";
import logo from "../../assets/images/parliament-logo.png";
import bg from "../../assets/images/login-bg.png";
import closeIcon from "../../assets/images/circle-close.svg";
import eyeIcon from "../../assets/images/eye.svg";
import eyeIcon2 from "../../assets/images/slash-eye.svg";
import Api from "../Api/Api";
import { useForm, SubmitHandler } from "react-hook-form";
import toast, { Toaster } from "react-hot-toast";
import { LoginSocialGoogle, LoginSocialFacebook, LoginSocialTwitter } from "reactjs-social-login";
import { FaGoogle, FaFacebookF, FaTwitter } from "react-icons/fa";

function Register({ setlogged_in }) {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
    setValue,
  } = useForm();
  const { SignUp, SocialMediaRegister } = Api();
  const navigate = useNavigate();

  const handleChange = (e) => {
    setName(e.target.value);
  };
  const handleChangeEmail = (e) => {
    setEmail(e.target.value);
  };

  const clearName = () => {
    setValue("name", "");
  };
  const clearEmail = () => {
    setValue("email", "");
  };
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const onSubmit = async (data) => {
    try {
      const response = await SignUp(data); // API call
      if (response.success) {
        toast.success("Registered successfully! Redirecting...");
        navigate("/otp");  // Redirect to OTP or other page
        reset();
        localStorage.setItem("email_id", response.parameters.email);
      } else {
        if (response.parameters.email) toast.error(response.parameters.email[0]);
        if (response.parameters.password) toast.error(response.parameters.password[0]);
      }
    } catch (error) {
      toast.error("Registration failed. Please try again.");
      console.error("Registration Error: ", error);
    }
  };

  // Google login handler
  const handleGoogleLoginSuccess = async ({ provider, data }) => {
    try {
      console.log(provider, data);
      toast.success("Google login successful! Redirecting...");

      // Send the Google user data to your backend registration API
      const response = await SocialMediaRegister({
        auth_token:data.access_token,
        name: data.name,
        provider:provider,
        email: data.email,
      });

      if (response.success) {
        setlogged_in(true);
        localStorage.setItem("auth_token", data.access_token);
        localStorage.setItem("user_name", data.name);
        navigate("/user/livevideos");
       
      } else {
        toast.error("Google login failed during registration.");
      }
    } catch (error) {
      console.error("Google login error:", error);
      toast.error("Google login failed. Please try again.");
    }
  };

  // Google login error handler
  const handleGoogleLoginFailure = (error) => {
    console.error("Google login failed:", error);
    toast.error("Google login failed. Please try again.");
  };



  const handleSuccessFacebook = (response) => {
    console.log('Login Success:', response);
    // Handle the user data or token here
  };

  const handleFailureFacebook = (error) => {
    console.log('Login Failed:', error);
    // Handle errors here
  };



  // twitter login 
  const handleSuccessTwitter = (response) => {
    console.log('Login Success:', response);
    // Handle the user data or token here
  };

  const handleFailureTwitter = (error) => {
    console.log('Login Failed:', error);
    // Handle errors here
  };

  return (
    <>
      <style>{`
        .footer_section {
          display: none;
        }
      
      `}</style>

      <div className="main_body mobile_login">
        <div className="container">
          <div className="row">
            <div className="col-12 col-sm-6 sm-order-2">
              <div className="login_left text-center">
                <div className="login_logo mb-3">
                  <img src={logo} alt="logo" />
                </div>
                <div className="title_part">
                  Our Meetings are available on <br></br>these Platforms
                </div>
                <div className="login_bg mobile_bg">
                  <img src={bg} alt="login" className="w-85 mobi_bg" />
                </div>
                <div className="copy_right d-sm-block d-md-none d-lg-none text-center t_small_none">
                  Copyright 2024. All Rights Reserved
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-6">
              <div className="form_container">
                <div className="login_logo-right mb-3">
                  <img src={logo} alt="logo" />
                </div>
                <div className="login_header-menu mb-3 register">
                  <ul>
                    {/* <li>
                                        <select class="form-control lang_select p-0">
                                            <option selected>English</option>
                                            <option value="1">French</option>
                                        </select>
                                    </li> */}
                    <li>
                      <Link to="/login">Sign in</Link>
                    </li>
                  </ul>
                </div>

                <div className="login_form-inner">
                  <div className="login_title">Create an Account</div>
                  <div className="login_sm-title">
                    Let’s get started with us !!!
                  </div>
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="input_from position-relative">
                      <input
                        type="text"
                        placeholder="Enter Name"
                        // value={name}
                        // onChange={handleChange}
                        name="name"
                        onChange={handleChange}
                        onKeyPress={(e) => {
                          const charCode = e.which ? e.which : e.keyCode;
                          if (
                            !(charCode >= 65 && charCode <= 90) && // A-Z
                            !(charCode >= 97 && charCode <= 122) && // a-z
                            charCode !== 8 && // Backspace
                            charCode !== 9
                          ) {
                            // Tab
                            e.preventDefault();
                          }
                        }}
                        {...register("name", { required: "Enter Name" })}
                      />
                      {errors.name && (
                        <span className="error_message">
                          {errors.name.message}
                        </span>
                      )}
                      {name == "" && (
                        <span className="text_close" onClick={clearName}>
                          <img src={closeIcon} alt="close" />
                        </span>
                      )}
                    </div>
                    <div className="input_from position-relative">
                      <input
                        type="email"
                        placeholder="Enter Email"
                        // value={email}
                        name="email"
                        onChange={handleChangeEmail}
                        {...register("email", {
                          required: "Email is required",
                        })}
                      />
                      {errors.email && (
                        <span className="error_message">
                          {errors.email.message}
                        </span>
                      )}
                      {email == "" && (
                        <span className="text_close" onClick={clearEmail}>
                          <img src={closeIcon} alt="close" />
                        </span>
                      )}
                    </div>
                    <div className="input_from position-relative">
                      <input
                        type={showPassword ? "text" : "password"}
                        placeholder="Enter Password"
                        // value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        {...register("password", {
                          required: "Password is required",
                          minLength: {
                            value: 8,
                            message:
                              "Password must be at least 8 characters long",
                          },
                        })}
                        onKeyDown={(e) => e.key === " " && e.preventDefault()}
                      // pattern="\d{6}"
                      />
                      {errors.password && (
                        <span className="error_message">
                          {errors.password.message}
                        </span>
                      )}
                      <span
                        className="text_visible"
                        onClick={togglePasswordVisibility}
                      >
                        {showPassword ? (
                          <img src={eyeIcon} alt="hide" />
                        ) : (
                          <img src={eyeIcon2} alt="show" />
                        )}
                      </span>
                    </div>
                    <div>
                      <button className="login_submit btn w-100 text-white">
                        Sign Up
                      </button>
                    </div>
                  </form>

                  {/* <div className='or_sign text-center position-relative my-4'>
                                    <span className='bg-white d-inline-block px-3'>Or continue with</span>
                                </div> */}


                  {/* Google login button */}
                  {/* <div className="google_btn my-3">
                    <LoginSocialGoogle
                      client_id="652369120452-b4tjpuvs7s0fd27659ueb527for95kcd.apps.googleusercontent.com"  // Replace with your Google Client ID
                      scope="openid profile email"
                      discoveryDocs="claims_supported"
                      onResolve={handleGoogleLoginSuccess}
                      onReject={handleGoogleLoginFailure}
                    >
                      <button className="btn border d-flex align-items-center gap-2">
                        <FaGoogle />Google
                      </button>
                    </LoginSocialGoogle>

                    <LoginSocialFacebook
                      appId="YOUR_FACEBOOK_APP_ID" 
                      onSuccess={handleSuccessFacebook}
                      onFailure={handleFailureFacebook}
                    >
                      <button className="btn border d-flex align-items-center gap-2">
                        <FaFacebookF /> Facebook
                      </button>
                    </LoginSocialFacebook>

                    <LoginSocialTwitter
                      consumerKey="F56QeAvBXIQZBdVePM8hmOsvl" 
                      consumerSecret="TmtifVhjjzeSUEMC7DWNAbd8pRUZbR7DEdmzVlmbmGsIJKK4Oz" 
                      onSuccess={handleSuccessTwitter}
                      onFailure={handleFailureTwitter}
                    >
                      <button className="btn border d-flex align-items-center gap-2">
                        <FaTwitter /> Twitter
                      </button>
                    </LoginSocialTwitter>

                  </div> */}

                  <div className="google_btn">
                    {/* <button className='btn'>
                                        <img src={google} alt="google" />
                                    </button> */}
                    <div className="have_login text-center my-4">
                      Already have an Account ? <Link to="/login">Login</Link>
                    </div>
                  </div>
                </div>

                <div className="copy_right text-center my-4 d-none d-sm-none d-md-block d-lg-block">
                  Copyright 2024. All Rights Reserved
                </div>

                {/* <ToastContainer /> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Register;
