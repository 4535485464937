import React, { useEffect, useState, useRef } from "react";
import "./header.css";
import { FiMenu } from "react-icons/fi";
import { CiSearch } from "react-icons/ci";
import defaultprofile from "../../assets/images/defaultprofile.jpg";
import { Link, NavLink } from "react-router-dom";
import Dropdown from "react-bootstrap/Dropdown";
import userIcon from "../../assets/images/defaultprofile.jpg";
import { FiUser } from "react-icons/fi";
import Api from "../Api/Api";
import { Line } from "react-chartjs-2";
import { useLocation, useParams } from "react-router-dom";
import { useProfile } from "../provider/ProfileProvider";
function Header() {
  const { isProfile, toggleProfile } = useProfile();
  const pathname = window.location.pathname;
  const [username, setUsername] = useState(null);
  // Split the pathname to get the parts
  const parts = pathname.split("/");

  // Extract the item ID from the parts
  const id = parts[parts.length - 1];

  const location = useLocation();
  const isProfilePage =
    location.pathname === "/admin/my-profile" ||
    location.pathname === "/user/my-profile" ||
    location.pathname === "/admin/settings/adduser" ||
    location.pathname === `/admin/settings/edituser/${id}`;
  // console.log(isProfilePage);
  const user_role = localStorage.getItem("user_role");
  const [isSidebarOpen, setSidebarOpen] = useState(false);
  // const UserName = localStorage.getItem("user_name");
  const [SearchData, setSearchData] = useState([]);
  // const isAdmin = userRole === 'ADMIN';
  const storedValue = localStorage.getItem("logged_in");
  const [logged_in, setlogged_in] = useState(storedValue);
  const [showResults, setShowResults] = useState(false);
  const { GlobalSearchList, GetUserProfile } = Api();
  const [ProfileImg, setProfileImg] = useState("");
  const searchRef = useRef(null);
  const toggleSidebar = () => {
    setSidebarOpen(!isSidebarOpen);
    if (!isSidebarOpen) {
      document.body.classList.add("sidebar_open");
    } else {
      document.body.classList.remove("sidebar_open");
    }
  };
  useEffect(() => {
    setlogged_in(storedValue === "true");
  }, [storedValue]);

  const [searchQuery, setSearchQuery] = useState("");

  const handleInputChange = async (event) => {
    setSearchQuery(event.target.value);
    setShowResults(true);
    const searchData = event.target.value;
    try {
      let data = await GlobalSearchList(searchData);
      console.log("GetMeetings", data.parameters.data);
      setSearchData(data.parameters.data);
    } catch (err) {
      console.log(err);
    }
  };

  // const filteredData = SearchData.filter(item =>
  //     item.video_title.toLowerCase().includes(searchQuery.toLowerCase())
  // );

  // const mobiletogglebar = () => {
  //     ;
  //     setSidebarOpen(!isSidebarOpen);
  //     if (!isSidebarOpen) {
  //         document.body.classList.add('sidebar_open');
  //     }
  //     else {
  //         document.body.classList.remove('sidebar_open');
  //     }
  // };

  const handleClickOutside = (event) => {
    // console.log("Clicked outside");
    if (searchRef.current && !searchRef.current.contains(event.target)) {
      setSearchQuery("");
      setShowResults(false);
    }
  };

  const handleLinkClick = () => {
    setSearchQuery(""); // Clear search query when link is clicked
    setShowResults(false); // Close search results dropdown
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const GetProfile = async () => {
    try {
      const response = await GetUserProfile();
      setProfileImg(response?.parameters.profile_image);
      console.log(response?.parameters?.first_name);
      setUsername(response?.parameters?.first_name);
      localStorage.setItem("user_name", response?.parameters?.first_name);
    } catch (error) {
      console.error("There was a problem with the fetch operation:", error);
    }
  };

  useEffect(() => {
    GetProfile();
    const UserName = localStorage.getItem("user_name");
    if (UserName) {
      setUsername(username ? username : UserName);
    }
  }, [isProfile]);
  useEffect(() => {
    // GetMeetings();
    GetProfile();
  }, []);

  return (
    <div className="header_container">
      <div className="main_header_box">
        <div className="search_with_bar">
          <div className="sidenav_icon desktop" onClick={toggleSidebar}>
            <FiMenu />
          </div>
          {/* <div className="sidenav_icon mobile" onClick={mobiletogglebar}>
                        <FiMenu />
                    </div> */}
          <div className="searces_bar position-relative" ref={searchRef}>
            {!isProfilePage && (
              <div className="name_filters position-relative">
                <input
                  type="search"
                  placeholder="Search"
                  value={searchQuery}
                  onChange={handleInputChange}
                />
                <span>
                  <CiSearch />
                </span>
              </div>
            )}
            {showResults && searchQuery !== "" && (
              <ul ref={searchRef}>
                {SearchData.length > 0 ? (
                  SearchData.map((item, index) => (
                    <li key={index}>
                      <Link
                        onClick={handleLinkClick}
                        to={
                          user_role === "ADMIN"
                            ? `/admin/meetings/meeting-detail/${item.id}`
                            : `/user/meetings/meeting-detail/${item.id}`
                        }
                      >
                        <span className="search_title">{item.video_title}</span>
                        {/* <span className="search_title">{item.video_description}</span> */}
                        <div className="d-flex justify-content-between search_date-time">
                          <span>{item.video_date}</span>
                          {item.from_time && item.to_time && (
                            <span>
                              {item.from_time.split(":")[0]}:
                              {item.from_time.split(":")[1]} -
                              {item.to_time.split(":")[0]}:
                              {item.to_time.split(":")[1]}
                            </span>
                          )}
                        </div>
                      </Link>
                    </li>
                  ))
                ) : (
                  <li className="px-3 no_data">No data available</li>
                )}
              </ul>
            )}
          </div>
        </div>
        <div className="">
          <div class="dropdown">
            <button
              class="btn btn-secondary dropdown-toggle user_button_customs"
              type="button"
              id="dropdownMenuButton1"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <img src={ProfileImg || userIcon} alt="" className="users_img" />
              <span className="user_name">{username}</span>
            </button>
            <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
              <li>
                {" "}
                {logged_in && (
                  <NavLink
                    to={
                      user_role === "ADMIN"
                        ? "/admin/my-profile"
                        : "/user/my-profile"
                    }
                    className="page_list"
                  >
                    <div className="inner_pages_list">
                      <div className="main_menu_icon">
                        <FiUser />
                      </div>
                      <div className="main_menu_name">Profile</div>
                    </div>
                  </NavLink>
                )}
              </li>
            </ul>
          </div>
          {/* <Dropdown>

                        <Dropdown.Toggle id="dropdown-basic" className="user_button_customs">
                            <img src={userIcon} alt="" className="users_img" />
                            <span className="user_name">{UserName}</span>
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                            {logged_in && (
                                <NavLink
                                    to={user_role === 'ADMIN' ? "/admin/my-profile" : "/user/my-profile"}
                                    className="page_list"
                                >
                                    <div className="inner_pages_list">
                                        <div className="main_menu_icon"><FiUser /></div>
                                        <div className="main_menu_name">Profile</div>
                                    </div>
                                </NavLink>
                            )}
                        </Dropdown.Menu>
                    </Dropdown> */}
        </div>
      </div>
    </div>
  );
}

export default Header;
